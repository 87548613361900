import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap' style={{display:"flex", flexDirection:"column", gap:"22px"}}>
                                <h2 className='h2_title'>AI Development Company in India: Revolutionizing Business with AI Development Services</h2>
                                <p>At Infograins, we are at the forefront of AI innovation, creating solutions that help businesses harness the power of artificial intelligence to drive efficiency, optimize operations, and deliver personalized customer experiences. With a dedicated team of AI experts and over 15 years of IT expertise, we help clients from various industries unlock the potential of AI to achieve measurable impact.</p>
                                {/* <ul style={{color:'#fff'}}>
                                    <li> Work with AI and ML developers who have over years of expertise.</li>
                                    <li> NDA confidentiality is 100%.</li>
                                    <li> An enthusiastic project manager</li>
                                    <li> Complete guidance from concept building to launching</li>
                                    <li> 100% Secure and Confidential</li>
                                </ul> */}
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
