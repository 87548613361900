import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import Carousel from 'react-bootstrap/Carousel';
export default function Features(props) {
    const data = props.data
    
    return (
        <>
            <section className='overview_sections py-3'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head text-center py-5'>
                                <h2>Project Features </h2>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='overview_img_wrapper'>
                           
                                {/* {
                                    data && data.project_features_image.length > 0 ?
                                        <Image src={data.project_features_image[0].image} className="img_side" alt='overview' fluid />
                                        :
                                         <Image src="https://dummyimage.com/600x400/000/fff" className="img_side" alt='overview' fluid />
                            } */}
                                <Image src=" https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/project_features/Asset_12_lb52nu" className="img_side" alt='overview' fluid />
                                {/* <Carousel>
                                    <Carousel.Item >
                                        <img
                                            className="d-block m-auto"
                                            src="https://1.bp.blogspot.com/-kK7Fxm7U9o0/YN0bSIwSLvI/AAAAAAAACFk/aF4EI7XU_ashruTzTIpifBfNzb4thUivACLcBGAsYHQ/s1280/222.jpg"
                                            alt="First slide"
                                            style={{ width: "400px", height: "400px", objectFit: "cover" }}
                                        />

                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <img
                                            className="d-block m-auto"
                                            src="https://i.pinimg.com/736x/4f/9b/d6/4f9bd6c198d5bd0e80be51b69d781d59.jpg"
                                            alt="Second slide"
                                            style={{ width: "400px", height: "400px", objectFit: "cover" }}
                                        />

                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <img
                                            className="d-block m-auto"
                                            src="https://www.whoa.in/download/natural-moon-night-photoshoot"
                                            alt="Third slide"
                                            style={{ width: "400px", height: "400px", objectFit: "cover" }}
                                        />

                                    </Carousel.Item>
                                </Carousel> */}

                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='overview_content py-5'>
                                <div className='list_content'>
                                    <ul>
                                        {
                                            data && data?.project_features?.map((e, i) => {
                                                return (
                                                    <li key={i}>
                                                        <div className='list_item' style={{ display: "flex", gap: "20px"}}>
                                                            <div className='list_icon'><BsFillCheckCircleFill /></div>
                                                            <div className='list_description'>
                                                                {e.content}
                                                            </div>
                                                        </div>
                                                        <br/> 
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}