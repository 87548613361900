import React from 'react'
import { Container, Row } from "react-bootstrap"
import NeWImg from '../../../assets/media/new-img.svg'
import NeWImg1 from '../../../assets/media/Requirement-Analysis.svg'
import NeWImg2 from '../../../assets/media/Design-and-Architecture.svg'
import NeWImg3 from '../../../assets/media/Development-and-Coding.svg'
import NeWImg00 from '../../../assets/media/Healthcare.svg'
import NeWImg0 from '../../../assets/media/real-estate.svg'
import NeWImg4 from '../../../assets/media/testing.svg'
import NeWImg5 from '../../../assets/media/deployment-integration.svg'
import NeWImg6 from '../../../assets/media/Maintenance-and-Support.svg'
import CommonCard from './common-card'





const ApiData = [
    {
        image: NeWImg1,
        title: 'Requirement Gathering',
        para: 'At Infograins, our blockchain development process begins with understanding your specific business needs and challenges. We collaborate with you to identify areas where blockchain can add value, ensuring the solution aligns with your goals and industry requirements.'
    },
    {
        image: NeWImg2,
        title: 'Design & Architecture',
        para: 'Once the requirements are clear, we design a robust system architecture tailored to your objectives. This includes selecting the right blockchain platform, defining transaction workflows, and incorporating security measures to ensure scalability and reliability.'
    },
    {
        image: NeWImg3,
        title: 'Development and Implementation',
        para: 'Our developers bring your blockchain solution to life by creating smart contracts, decentralized applications, and essential features. Throughout this process, we focus on delivering a user-friendly, secure, and efficient system that meets your expectations.'
    },
    {
        image: NeWImg4,
        title: 'Testing and Security Validation',
        para: 'We conduct thorough testing at every stage of development to ensure the solution’s functionality and security. From unit testing to security audits, our goal is to deliver a flawless and resilient blockchain system.'
    },
    {
        image: NeWImg5,
        title: 'Deployment and Integration',
        para: 'Once the solution is tested, we deploy it seamlessly into your live environment. This includes setting up blockchain nodes, configuring the network, and integrating the solution with your existing systems for smooth operations.'
    },
    {
        image: NeWImg6,
        title: 'Ongoing Maintenance and Support',
        para: 'Even after deployment, we provide continuous maintenance and support to ensure your blockchain solution remains optimized. Our team resolves issues, implements updates, and ensures uninterrupted performance for long-term success.'
    }
]
const NewCard = () => {

    return (
        <>
            <section className='w3Card-wrap' style={{ background: "unset" }}>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Our Blockchain Development Process</h2>
                    </div>
                    <div className='w3Card-both'>
                        <img src={NeWImg} alt="" style={{ marginBottom: "30px" }} />
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para, image }, index) => {
                                    return <CommonCard key={index} title={title} para={para} image={image} index={index} type={"blockchain-process"}/>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default NewCard