import React from 'react'
import { Container, Row } from "react-bootstrap"
import NeWImg1 from '../../../assets/media/expertise_ai.svg' 
import NeWImg2 from '../../../assets/media/impact_ai.svg'
import NeWImg3 from '../../../assets/media/reflect_ai.svg'
import NeWImg4 from '../../../assets/media/iteration_ai.svg'
import NeWImg5 from '../../../assets/media/file-transfer_ai.svg'
import NeWImg6 from '../../../assets/media/assistance_ai.svg'
import CommonCard from '../blockchain_development/common-card'

// 1. Proven Expertise Across Industries
// With years of experience in AI development, we have successfully implemented AI-driven solutions in diverse sectors, including healthcare, finance, retail, logistics, and manufacturing
// 2. Tailored Solutions for Maximum Impact
// At Infograins, we understand that every business has distinct needs. That’s why we focus on delivering bespoke AI solutions tailored to your goals. 
// 3. Cutting-Edge Technology and Tools
// We leverage the latest AI technologies and tools, including deep learning frameworks, machine learning algorithms, and cloud-based AI platforms
// 4. End-to-End Development Services
//  Our streamlined development process ensures that your AI solution is robust, user-friendly, and seamlessly integrated into your existing infrastructure.
// 5. Commitment to Data Security and Compliance
//  We follow stringent security protocols and adhere to global compliance standards, ensuring that your data remains protected while achieving optimal performance in your AI solutions.
// 6. Dedicated Support and Continuous Optimization
// Our partnership doesn’t end with deployment. We offer ongoing maintenance, support, and optimization services to ensure that your AI systems continue to evolve with your business needs.

const ApiData = [
    {
        image: NeWImg1,
        title: 'Proven Expertise Across Industries',
        para: 'With years of experience in AI development, we have successfully implemented AI-driven solutions in diverse sectors, including healthcare, finance, retail, logistics, and manufacturing.'
    },
    {
        image: NeWImg2,
        title: 'Tailored Solutions for Maximum Impact',
        para: 'At Infograins, we understand that every business has distinct needs. That’s why we focus on delivering bespoke AI solutions tailored to your goals.'
    },
    {
        image: NeWImg3,
        title: 'Cutting-Edge Technology and Tools',
        para: 'We leverage the latest AI technologies and tools, including deep learning frameworks, machine learning algorithms, and cloud-based AI platforms.'
    },
    {
        image: NeWImg4,
        title: 'End-to-End Development Services',
        para: 'Our streamlined development process ensures that your AI solution is robust, user-friendly, and seamlessly integrated into your existing infrastructure.'
    },
    {
        image: NeWImg5,
        title: 'Commitment to Data Security and Compliance',
        para: 'We follow stringent security protocols and adhere to global compliance standards, ensuring that your data remains protected while achieving optimal performance in your AI solutions.'
    },
    {
        image: NeWImg6,
        title: 'Dedicated Support and Continuous Optimization',
        para: 'Our partnership doesn’t end with deployment. We offer ongoing maintenance, support, and optimization services to ensure that your AI systems continue to evolve with your business needs.'
    }
]
const WhyChooseInfograins = () => {

    return (
        <>
            <section className='w3Card-wrap'  style={{ background: "unset" }}>
                <Container>
                    <div className='w3Card-title ai_w3Card_center'>
                        <h2 className='ai_h2_title'>Why Choose Infograins as Your AI Development Company in India? </h2>
                        <p className='ai_p_body'>Infograins, a trusted AI development company in India, stands out as a leader in delivering innovative, scalable, and industry-specific AI solutions. Here’s what makes us your ideal AI development partner.</p>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                            {ApiData.map(({ title, para, image }, index) => {
                                    return <CommonCard key={index} title={title} para={para} image={image} index={index} type={"why_choose_infograins"} />
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default WhyChooseInfograins;