import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
export default function Solutions(props) {
    const data = props.data
    return (
        <>
            {
                data && data?.solutions?.length > 0 ?
                    <section className='solution_section'>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="head">
                                        <h2>Solutions</h2>

                                    </div>
                                </Col>
                                {
                                    data?.solutions?.map((e, i) => {
                                        return (
                                            <Col lg={4} key={i}>
                                                <div className='solution_content' >
                                                    <Image src={e?.image} fluid />
                                                    <h2 style={{paddingBottom:"6px", fontSize:"24px"}}>{e.title}</h2>
                                                    <p>{e.content}</p>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </section>
                    :
                    " "
            }

        </>
    )
}
