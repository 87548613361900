import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import Home from "../components/pages/landingPages/Home";
import Web3 from "../components/pages/blockchain/web3/Web3";
import BlogMain from "../components/pages/blog/BlogMain";
import SingleBlog from "../components/pages/blog/SingleBlog";
import AboutUs from "../components/pages/aboutUs/AboutUs";
import ContactUs from "../components/pages/contactUs/ContactUs";
import OurTeam from "../components/pages/ourTeam/OurTeam";
import NotFound from "../components/pages/NotFound";
import UnityGame from "../components/pages/games/unityGame/UnityGame";
import Main_nft from "../components/pages/NFT/main_nft";

import ProductWeb from "../components/pages/product/productWeb/ProductWeb";
import Resources from "../components/pages/resources/Resources";
import MainVenture from "../components/pages/venture/MainVenture";
import ProductPage from "../components/pages/product/singleProduct/ProductPage";
import Privacy from "../components/pages/PrivacyPolicy/privacy";
import TermCondition from "../components/pages/Term_and_condition/TermCondition";
import Career from "../components/pages/career/Career";
import CareerApply from "../components/pages/career/CareerApply";
import SiteMap from "../components/pages/SiteMap";
import HireMain from "../components/pages/hire/HireMain";
import Main from "../components/pages/caseStudies/Main";
import CaseStudiesDetails from "../components/pages/caseStudies/CaseStudiesDetails";
import MainEngagement from "../components/pages/engagementModels/MainEngagement";
import HireDeveloperAdd from "../components/pages/hiredeveloperadd/HireDeveloperAdd";
import PressMain from "../components/pages/pressRelease/PressMain";
import PreviousEventDetails from "../components/pages/pressRelease/PreviousEventDetails";
import Articles from "../components/pages/articles/Articles";
import SingleArticle from "../components/pages/articles/SingleArticle";
import Hyderabad from "../components/pages/hyderabad/Hyderabad";
import KnowMore from "../components/pages/knowMore/KnowMore";
import GameIndex from "../components/pages/games/unityGame/GameIndex";
import BlockchainIndex from "../components/pages/blockchain/web3/BlockchainIndex";
import NftIndex from "../components/pages/NFT/NftIndex";
import HireIndex from "../components/pages/hire/HireIndex";
import ProductIndex from "../components/pages/product/ProductIndex";
import Layout from "./Layout";
import EventIndex from "../components/pages/knowMore/EventIndex";
import MobileIndex from "../components/pages/android_app/mobileIndex";
import FlutterIndex from "../components/pages/flutter_app/mobileIndex";
import IonicIndex from "../components/pages/ionic_app/mobileIndex";
import IOSIndex from "../components/pages/ios_app/mobileIndex";
import KotlinIndex from "../components/pages/kotlin_app/mobileIndex";
import ReactNativeIndex from "../components/pages/react_native_app/mobileIndex";
import SwiftIndex from "../components/pages/swift_app/mobileIndex";
import UnityIndex from "../components/pages/unity_app/mobileIndex";
import AngularJs from "../components/pages/angular_js/main_nft";
import CodeIgniter from "../components/pages/codeigniter/main_nft";
import JavaScript from "../components/pages/javascript/main_nft";
import NextJs from "../components/pages/next_js/main_nft";
import NodeJs from "../components/pages/node_js/main_nft";
import PHP from "../components/pages/php/main_nft";
import Python from "../components/pages/python/main_nft";
import ReactJs from "../components/pages/react_js/main_nft";
import TypeScript from "../components/pages/typescript/main_nft";
import Wordpress from "../components/pages/wordpress/main_nft";
import AlexaSkills from "../components/pages/alexa_skills/mobileIndex";
import BusinessIntel from "../components/pages/business_intell/mobileIndex";
import DataAnalytics from "../components/pages/data_analytics/mobileIndex";
import NLP from "../components/pages/NLP/mobileIndex";
import ObjectRecognition from "../components/pages/object_recognition/mobileIndex";
import SentimentAnalysis from "../components/pages/sentiment_analysis/mobileIndex";
import TextToSpeech from "../components/pages/text_to_speech/mobileIndex";
import IotApp from "../components/pages/iotApp/UnityGame";
import IotHardwarePrototyping from "../components/pages/iotHardware/UnityGame";
import IotAnalytics from "../components/pages/iotAnalytics/UnityGame";
import IotAutomation from "../components/pages/iotAutomation/UnityGame";
import DesignWebApp from "../components/pages/designWebApp/main_nft";
import DesignMobileApp from "../components/pages/designMobileApp/main_nft";
import DesignWireframing from "../components/pages/designWireframing/main_nft";
import DesignUI from "../components/pages/designUI/main_nft";
import SalesforceDevelopment from "../components/pages/salesforceDevelopment/main_nft";
import SalesforceConsulting from "../components/pages/salesforceConsulting/main_nft";
import SalesforceImplementation from "../components/pages/salesforceImplementation/main_nft";
import TestingBlockchainApp from "../components/pages/testingBlockchainApp/main_nft";
import TestingEcommerce from "../components/pages/testingEcommerce/main_nft";
import TestingIoTApp from "../components/pages/testingIoTApp/main_nft";
import TestingMobileApp from "../components/pages/testingMobileApp/main_nft";
import TestingWebApp from "../components/pages/testingWebApp/main_nft";
import LandingIndex from "../components/new-pages/landing/LandingIndex";
import ItStaffingSolutions from "../components/pages/itStaffingSolutions/HireMain";
import BlockchainPageIndex from "../components/pages/blockchain_index/main_nft";
import MobileDevPageIndex from "../components/pages/mob_development_index/main_nft";
import WebDevPageIndex from "../components/pages/web_development_index/main_nft";
import DesignPageIndex from "../components/pages/design_index/main_nft";
import AIPageIndex from "../components/pages/ai_development_index/main_nft";
import SoftwarePageIndex from "../components/pages/software_index/main_nft";
import SoftwareService from "../components/pages/software/SoftwareService";
import ThankYou from "../components/pages/software/ThankYou";
import BlockchainDevelopmentIndex from "../components/pages/blockchain_development/mobileIndex";
import GameDevelopment from "../components/pages/game_development/UnityGame";
import RoboticsIndex from "../components/pages/robotics/mobileIndex";
import AgricultureIndex from "../components/agriculture";
import IPLpredictionIndex from "../components/ipl-prediction";
import DiabetesPredictionIndex from "../components/diabetic";
import LeafPredictionIndex from "../components/leaf_prediction";
import PropertyForecasterIndex from "../components/property_forecaster";
import VehicleNumberPlateIndex from "../components/automatic_number_plate_recognition/Index";

const Index = () => {
  // ======================================================= For Route API ==================================================================
  useEffect(() => {
    scrollSmoothTo("top");
  }, []);
  // ======================================================= For Route API ==================================================================

  function scrollSmoothTo(elementId) {
    var element = document.getElementById(elementId);
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  const para = window.location;

  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route element={<Layout />}>
            {/* <Route path="/" element={<Home demo={scrollSmoothTo} />} /> */}
            <Route
              path="/blockchain/:slug"
              element={<Web3 demo={scrollSmoothTo} />}
            />
            <Route path="/blog" element={<BlogMain demo={scrollSmoothTo} />} />
            <Route
              path="/blog/:blog_slug"
              element={<SingleBlog demo={scrollSmoothTo} />}
            />
            <Route
              path="/whitepaper"
              element={<Articles demo={scrollSmoothTo} />}
            />
            <Route
              path="/whitepaper/:article_slug"
              element={<SingleArticle demo={scrollSmoothTo} />}
            />
            <Route path="/aboutUs" element={<AboutUs demo={scrollSmoothTo} />} />
            <Route
              path="/contactUs"
              element={<ContactUs demo={scrollSmoothTo} />}
            />
            <Route path="/team" element={<OurTeam demo={scrollSmoothTo} />} />
            <Route
              path="/game/:game_slug"
              element={<UnityGame demo={scrollSmoothTo} />}
            />
            <Route
              path="/nft/:nft_slug"
              element={<Main_nft demo={scrollSmoothTo} />}
            />
            <Route
              path="/products"
              element={<ProductWeb demo={scrollSmoothTo} />}
            />
            <Route path="/career" element={<Career demo={scrollSmoothTo} />} />
            <Route
              path="/career/:apl_slug"
              element={<CareerApply demo={scrollSmoothTo} />}
            />
            <Route
              path="/product/:product_slug"
              element={<ProductPage demo={scrollSmoothTo} />}
            />
            <Route path="/privacy" element={<Privacy demo={scrollSmoothTo} />} />
            <Route
              path="/term-and-condition"
              element={<TermCondition demo={scrollSmoothTo} />}
            />
            <Route path="/siteMap" element={<SiteMap demo={scrollSmoothTo} />} />
            <Route
              path="/hire-developers/:hire_slug"
              element={<HireMain demo={scrollSmoothTo} />}
            />
            <Route
              path="/case-study/:case_slug"
              element={<CaseStudiesDetails demo={scrollSmoothTo} />}
            />
            <Route path="/case-study" element={<Main demo={scrollSmoothTo} />} />
            <Route
              path="/hire-developer-uae"
              element={<HireDeveloperAdd demo={scrollSmoothTo} />}
            />
            <Route
              path="/engagement-models"
              element={<MainEngagement demo={scrollSmoothTo} />}
            />
            <Route
              path="/a-venture-with-cdn-solution"
              element={<MainVenture demo={scrollSmoothTo} />}
            />
            <Route
              path="/press-release"
              element={<PressMain demo={scrollSmoothTo} />}
            />
            <Route
              path="/press-release/:id"
              element={<PreviousEventDetails demo={scrollSmoothTo} />}
            />
            <Route
              path="/hyderabad"
              element={<Hyderabad demo={scrollSmoothTo} />}
            />
            {/* <Route
              path="/blockchain"
              element={<BlockchainIndex demo={scrollSmoothTo} />}
            /> */}
            <Route
              path="/nft"
              element={<NftIndex demo={scrollSmoothTo} />}
            />
            <Route
              path="/hire-developers"
              element={<HireIndex demo={scrollSmoothTo} />}
            />
            <Route
              path="/product"
              element={<ProductIndex demo={scrollSmoothTo} />}
            />
            <Route
              path="/game"
              element={<GameIndex demo={scrollSmoothTo} />}
            />
            <Route
              path="/event"
              element={<EventIndex demo={scrollSmoothTo} />}
            />
            <Route path="*" element={<NotFound demo={scrollSmoothTo} />} />
            {/* ===================== Static Page ============================== */}
            <Route path="/mobile-development/android-app" element={<MobileIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/flutter-app" element={<FlutterIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/ionic-app" element={<IonicIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/ios-app" element={<IOSIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/kotlin-app" element={<KotlinIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/react-native-app" element={<ReactNativeIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/swift-app" element={<SwiftIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development/unity-app" element={<UnityIndex demo={scrollSmoothTo} />} />
            <Route path="/web-development/angular-js" element={<AngularJs demo={scrollSmoothTo} />} />
            <Route path="/web-development/codeigniter" element={<CodeIgniter demo={scrollSmoothTo} />} />
            <Route path="/web-development/javascript" element={<JavaScript demo={scrollSmoothTo} />} />
            <Route path="/web-development/next-js" element={<NextJs demo={scrollSmoothTo} />} />
            <Route path="/web-development/node-js" element={<NodeJs demo={scrollSmoothTo} />} />
            <Route path="/web-development/php" element={<PHP demo={scrollSmoothTo} />} />
            <Route path="/web-development/python" element={<Python demo={scrollSmoothTo} />} />
            <Route path="/web-development/react-js" element={<ReactJs demo={scrollSmoothTo} />} />
            <Route path="/web-development/typescript" element={<TypeScript demo={scrollSmoothTo} />} />
            <Route path="/web-development/wordpress" element={<Wordpress demo={scrollSmoothTo} />} />
            <Route path="/ai-development/alexa-skills" element={<AlexaSkills demo={scrollSmoothTo} />} />
            <Route path="/ai-development/business-intelligence" element={<BusinessIntel demo={scrollSmoothTo} />} />
            <Route path="/ai-development/data-analytics" element={<DataAnalytics demo={scrollSmoothTo} />} />
            <Route path="/ai-development/natural-language-processing" element={<NLP demo={scrollSmoothTo} />} />
            <Route path="/ai-development/object-recognition" element={<ObjectRecognition demo={scrollSmoothTo} />} />
            <Route path="/ai-development/sentiment-analysis" element={<SentimentAnalysis demo={scrollSmoothTo} />} />
            <Route path="/ai-development/text-to-speech" element={<TextToSpeech demo={scrollSmoothTo} />} />
            <Route path="/iot/iot-app" element={<IotApp demo={scrollSmoothTo} />} />
            <Route path="/iot/iot-hardware-prototyping" element={<IotHardwarePrototyping demo={scrollSmoothTo} />} />
            <Route path="/iot/iot-dashboard-and-analytics" element={<IotAnalytics demo={scrollSmoothTo} />} />
            <Route path="/iot/smart-home-automation" element={<IotAutomation demo={scrollSmoothTo} />} />

            <Route path="/design/web-app" element={<DesignWebApp demo={scrollSmoothTo} />} />
            <Route path="/design/mobile-app" element={<DesignMobileApp demo={scrollSmoothTo} />} />
            <Route path="/design/wireframing-and-prototyping" element={<DesignWireframing demo={scrollSmoothTo} />} />
            <Route path="/design/ui/ux-design" element={<DesignUI demo={scrollSmoothTo} />} />

            <Route path="/salesforce-solutions/development" element={<SalesforceDevelopment demo={scrollSmoothTo} />} />
            <Route path="/salesforce-solutions/consulting" element={<SalesforceConsulting demo={scrollSmoothTo} />} />
            <Route path="/salesforce-solutions/implementation" element={<SalesforceImplementation demo={scrollSmoothTo} />} />

            <Route path="/software-testing/blockchain-app" element={<TestingBlockchainApp demo={scrollSmoothTo} />} />
            <Route path="/software-testing/ecommerce" element={<TestingEcommerce demo={scrollSmoothTo} />} />
            <Route path="/software-testing/iot-app" element={<TestingIoTApp demo={scrollSmoothTo} />} />
            <Route path="/software-testing/mobile-app" element={<TestingMobileApp demo={scrollSmoothTo} />} />
            <Route path="/software-testing/web-app" element={<TestingWebApp demo={scrollSmoothTo} />} />
            <Route path="/hire-developers/it-staffing-solutions" element={<ItStaffingSolutions demo={scrollSmoothTo} />} />

            <Route path="/" element={<LandingIndex demo={scrollSmoothTo} />} />
            <Route path="/blockchain" element={<BlockchainPageIndex demo={scrollSmoothTo} />} />
            <Route path="/mobile-development" element={<MobileDevPageIndex demo={scrollSmoothTo} />} />
            <Route path="/web-development" element={<WebDevPageIndex demo={scrollSmoothTo} />} />
            <Route path="/design" element={<DesignPageIndex demo={scrollSmoothTo} />} />
            <Route path="/ai-development-services" element={<AIPageIndex demo={scrollSmoothTo} />} />
            <Route path="/software-testing" element={<SoftwarePageIndex demo={scrollSmoothTo} />} />
            <Route path="/thank-you/" element={<ThankYou demo={scrollSmoothTo} />} />
            <Route path="/blockchain-development" element={<BlockchainDevelopmentIndex demo={scrollSmoothTo} />} />
            <Route path="/game-development" element={<GameDevelopment demo={scrollSmoothTo} />} />
            <Route path="/robotics" element={<RoboticsIndex demo={scrollSmoothTo} />} />
          </Route>
          <Route path="/software-development-services" element={<SoftwareService demo={scrollSmoothTo} />} />
          <Route path="/event/:id" element={<KnowMore demo={scrollSmoothTo} />}></Route>
          <Route path="/:id" element={<KnowMore demo={scrollSmoothTo} />}></Route>
          <Route path="/agriculture" element={<AgricultureIndex demo={scrollSmoothTo}/>}/>
          <Route path="/ipl-prediction" element={<IPLpredictionIndex demo={scrollSmoothTo}/>}/>
          <Route path="/diabetes-prediction" element={<DiabetesPredictionIndex demo={scrollSmoothTo}/>}/>
          <Route path="/leaf-prediction" element={<LeafPredictionIndex demo={scrollSmoothTo}/>}/>
          <Route path="/property-forecaster" element={<PropertyForecasterIndex demo={scrollSmoothTo}/>}/>
          <Route path="/automatic-number-plate-recognition" element={<VehicleNumberPlateIndex demo={scrollSmoothTo}/>}/>

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Index;
// basename='/infograins'
// "homepage": "https://infograinsdevelopment.com/infograins",

// "postbuild": "react-snap"
