import React, { useState } from "react";
import top1 from "../../../assets/images/konwMore/Group 31.png";
import top2 from "../../../assets/images/konwMore/IMAGE.png";

import top3 from "../../../assets/images/konwMore/IMAGE-1.png";
import top4 from "../../../assets/images/konwMore/IMAGE-2.png";
import top5 from "../../../assets/images/konwMore/IMAGE-3.png";
import top6 from "../../../assets/images/konwMore/IMAGE-4.png";
import { TbArrowBigRightLine } from 'react-icons/tb'
import asset2 from "../../../assets/images/konwMore/asset 02.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../knowMore/knowMore.css";
import "react-phone-input-2/lib/bootstrap.css";
import { Helmet } from "react-helmet";
import $ from "jquery";
import WhatsApp from '../../../assets/media/whatsapp.webp';

import { FaFacebookF, FaInstagram, FaLinkedinIn, FaSkype, FaTwitter, } from "react-icons/fa";

import { Link, useNavigate, useParams } from "react-router-dom";
import { BiUpArrowAlt } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
// import KeyPoints from "../landingPages/KeyPoints";
import KeyPoints from './KeyPoints'
import axios from "axios";
import Loader from "react-js-loader";
import { Controller, useForm } from "react-hook-form";
import Form from "./Form";

export default function SoftwareService() {
  const navigate = useNavigate()
  const [ed, seted] = useState(1);
  const nameReg = `^[A-Za-z][A-Za-z0-9\s]{3,20}$`;
  const emailReg = `^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$`;
  const contactReg = `^[0-9]{10,}`;
  const param = useParams();
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_us, setcontact_us] = useState("");
  const [remark, setRemark] = useState("");
  const [know, setknow] = useState({});
  const notify = () =>
    toast.success("Thanks for contacting us. We will contact you shortly");
  $("ul li div").click(function () {
    $("li div").removeClass("active2");

    $(this).addClass("active2");

    $("#shows").removeClass("hideOne");
    $(this).addClass("showOne");
  });
  // ============================================ form submit ============================================
  const { handleSubmit, reset, register, formState: { errors }, control } = useForm()
  const onSubmit = ({ userName, userEmail, userNumber, userMessage }) => {
    setLoader(true);
    const bodyData = {
      "name": userName,
      "email": userEmail,
      "contact_us": userNumber,
      "remark": userMessage
    }
    console.log(bodyData);
    axios.post(`${process.env.REACT_APP_BASE_URL}event/event_contact/`, bodyData).then((data) => {
      reset();
      setLoader(false);
      notify();
      navigate('/thank-you/')
      setTimeout(() => {
        navigate('/')
      }, 4000);
    }).catch((error) => {
      setLoader(false);
      console.log(error);
    })
  }
  return (
    <>
      <Helmet>
        <meta name="description" content="Are you looking for an IT partner that can help you with all your IT needs? Look no further than IT Solutions. We offer a wide range of services, including software development, mobile app development, IoT, blockchain, and website design. We can help you improve your business operations, increase your sales, and provide better customer service" />
        <title>Your One-Stop Solution for Comprehensive IT Services</title>
        <link href="https://www.infograins.com/software-development-services" rel="canonical" />
      </Helmet>
      <ToastContainer />
      <section>
        {/* Main Section Start */}
        <div className=" konw-main-bg" >
          <div className="d-flex justify-content-center py-4">
            <div className="">
              <Link to='/'>
                <img className="float-end " src={top1} alt="" />
              </Link>
            </div>

            {/* <div className="col-md-6 top-div2 col-sm-6  p-3">
              <img className="top-img float-start" src={top2} alt="" />
            </div> */}
          </div>
          <div className="d-flex justify-content-center text-white py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 know-main-text">
                  <h1>Looking For  Top-Notch <br /> <span style={{ color: '#fdbf17' }}> IT Solutions?</span> </h1>
                  <ul style={{ marginTop: '1em' }}>
                    <li className="text-white" style={{ fontSize: '1.5em', fontWeight: 'bold', fontStyle:'italic', lineHeight:'2' }}><TbArrowBigRightLine /> Software Development</li>
                    <li className="text-white" style={{ fontSize: '1.5em', fontWeight: 'bold', fontStyle:'italic', lineHeight:'2' }}><TbArrowBigRightLine /> Website Development</li>
                    <li className="text-white" style={{ fontSize: '1.5em', fontWeight: 'bold', fontStyle:'italic', lineHeight:'2' }}><TbArrowBigRightLine /> Mobile Application Development</li>
                    <li className="text-white" style={{ fontSize: '1.5em', fontWeight: 'bold', fontStyle:'italic', lineHeight:'2' }}><TbArrowBigRightLine /> Blockchain Solutions</li>
                    <li className="text-white" style={{ fontSize: '1.5em', fontWeight: 'bold', fontStyle:'italic', lineHeight:'2' }}><TbArrowBigRightLine /> AI / ML Solutions</li>
                  </ul>
                  <p style={{fontSize: "20px",fontWeight: '600',marginTop: '1em'}} className="text-white">Look no further! We specialize in providing comprehensive solutions for all your IT needs.</p>
                </div>
                <div className="col-md-6 know-main-form">
                  {/* schedule  start  */}
                  <div className="p-2">
                    <div className="">
                      <h1 className="text-center mb-4 ">
                        Schedule
                        <span className="konw-schedule-span"> Free Consultation</span>
                      </h1>
                    </div>

                    <div className="container text-white ">
                      <Form />
                    </div>
                  </div>
                  {/* schedule  End  */}
                </div>
              </div>
            </div>
          </div>



          <div className="d-flex justify-content-center text-white" style={{ backgroundColor: "#11161d", margin: "2em 0", padding: '1em 0' }}>
            <div className="container">
              <div className="col-md-12 p-3  ">
                <div className="d-flex justify-content-between align-items-center flex-wrap schedule_content">
                  <div className="p-2 me-2 toUppercase" style={{ flexBasis: '76%', fontSize: '1.4em', wordSpacing: '5px', textTransform: 'capitalize', color: '#fdbf17' }}>
                    <p className="text-white" > <span style={{fontWeight:'bold', fontSize:'1.5em', color:'#fdbf17'}}> Hire Our Expert Developers</span> for Your Next Project</p>
                    <p className="text-white" >Need a dedicated team for your project? Our skilled developers are ready to bring your vision to life!</p>
                    </div>
                  <div>
                    <a href="https://calendly.com/infograins-business-trip/consult-with-it-experts-infograins"
                      target={"_blank"} className="know-schedu-btn ms-2" style={{ backgroundColor: '#fa502b', padding: "17px 38px", fontWeight: "600", borderRadius: "40px", display: "inline-block" }}>
                      Schedule Meeting
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container text-light ">
            <hr className="text-white" />
            <div className="row mt-3 ">
              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div">
                <div className="d-flex ">
                  <div>
                    <img src={top3} alt="" />
                  </div>
                  <div className="mt-2">
                    Top enterprise app development companies
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div">
                <div className="d-flex">
                  <div>
                    <img src={top4} alt="" />
                  </div>
                  <div className="mt-2">
                    Top Custom Software Development companies
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div">
                <div className="d-flex">
                  <div>
                    <img src={top5} alt="" />
                  </div>
                  <div className="mt-2">
                    Top 20 e-commerce Development companies
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div ">
                <div className="d-flex">
                  <div>
                    <img src={top6} alt="" />
                  </div>
                  <div className="mt-2">Top mobile app Development company</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="software-service-box">
          <div className="software-service-box-title">
            <p>Infograins is building an Affordable, Easy-to-use & Feature-Rich Web, App, custom software For Your Business.</p>
          </div>
          <KeyPoints />
        </section>
        {/* our areas  start  */}
        <div className="know-our-areas  software-service-service">
          <div className="container">
            <h1 className="text-center p-5 know-our-h1">
              Our Areas of Expertise
            </h1>
            <div className="row justify-content-center">
              <div className="col-md-6 col-xl-4">
                <Link to={'/blockchain-development'}>
                  <div className="know-our-divs">
                    <span >Blockchain Development</span>
                    <p>Empowering businesses with secure, transparent, and decentralized blockchain solutions.</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-6 col-xl-4">
                <Link to={'/mobile-development'}>
                  <div className="know-our-divs">
                    <span >Mobile Development</span>
                    <p>Crafting user-friendly mobile apps that redefine the way you engage with your audience.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/web-development'}>
                  <div className="know-our-divs">
                    <span >Web Development</span>
                    <p>Building responsive websites that make a lasting impression and drive online success.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/design'}>
                  <div className="know-our-divs">
                    <span >Design</span>
                    <p>Transforming concepts into captivating visuals, enhancing user experiences across all platforms.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/iot/iot-app'}>
                  <div className="know-our-divs">
                    <span >IoT (Internet of Things)</span>
                    <p>Creating smart, interconnected devices that bridge the physical and digital worlds.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/ai-development-services'}>
                  <div className="know-our-divs">
                    <span >AI Development</span>
                    <p>Leveraging artificial intelligence to optimize processes, gain insights, and boost efficiency.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/software-testing'}>
                  <div className="know-our-divs">
                    <span >Software Testing</span>
                    <p>Ensuring the reliability and quality of your software through rigorous testing.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/game-development'}>
                  <div className="know-our-divs">
                    <span >Game Development</span>
                    <p>Immersive and engaging game development that brings entertainment to life</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/robotics'}>
                  <div className="know-our-divs">
                    <span >Robotics</span>
                    <p>Pioneering innovative robotics solutions for a wide range of industries.</p>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 col-xl-4">
                <Link to={'/blockchain/metaverse-development/'}>
                  <div className="know-our-divs">
                    <span >Metaverse</span>
                    <p>Shaping the future of digital experiences in the Metaverse with cutting-edge technologies.</p>
                  </div>
                </Link>
              </div>
            </div>
            {/* <div className="row p-3 text-center "> */}
            {/* </div> */}
          </div>
        </div>
        {/* our areas  End  */}
        {/* Main Section End */}
        {/* Event / Trip Start*/}
        {/* <div className="know-event-trip bg-dark ">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 col-sm-12 col-xl-6 mt-5 mb-5 p-5">
                <img src={asset3} alt="" />
              </div>
              <div className="col-md-12 col-sm-12 col-xl-6 mt-5 mb-5 text-white p-5">
                <h1 className="mt-4">setionSecHead</h1>
                <p className="text-white mt-2">setionSecText</p>

                <p className="text-white mt-2">
                  Connect with team Infograins at
                  <span style={{ fontWeight: "bold" }}> eventName </span>
                  and be a part of their journey toward becoming
                </p>

                <p className="text-white mt-2">
                  <BsStarFill /> Inspiration
                </p>
                <p className="text-white">
                  <BsStarFill /> Innovative
                </p>
                <p className="text-white">
                  <BsStarFill /> Irreplaceable
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* Event / Trip END*/}

        {/* About Us Start */}
        <div className="know-About-us bg-dark ">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 col-xl-6 mt-5 text-white p-5">
                <h1 className=" mt-5">About Us</h1>
                <p className="text-white mt-3">
                  Infograins is an accomplished software development business
                  that offers the creation of blockchain applications. Our
                  implementation technique is centred on your objectives,
                  regardless of the type of blockchain solution you require. We
                  help in developing a variety of services, ranging from highly
                  decentralized DApps to completely secure Smart Contracts,
                  using platforms like Solidity and Ethereum. We distinguish
                  ourselves from others by utilizing modern technologies
                </p>
                {/* <p className="text-white mt-3">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Atque totam voluptatem hic repellat dolore quisquam
                    reprehenderit?
                  </p>
                  <p className="text-white mt-3">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Magni voluptatibus error ipsum? Voluptatem eveniet amet
                    corporis, doloremque vitae enim maxime. Illo id repudiandae
                    consequuntur magnam earum?
                  </p> */}
              </div>
              <div className="col-md-12 col-xl-6 mt-3 mb-5 p-5">
                <img src={asset2} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* About Us END*/}

        {/* Few Reason start  */}
        <div className="know-few-reason p-4 ">
          <div className="container">
            <h1 className="text-center p-5">
              Few Reasons to do business with us
            </h1>
            <div className="row p-4">
              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 ">01.</div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    Infograins Software Solutions has a core team of blockchain
                    experts who are well-versed with the latest trends and
                    frameworks.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 ">02.</div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    We provide customized industry-specific solutions using an
                    agile development process.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 ">03.</div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    Our focus is on providing a client-centric service whereby
                    we collaborate with clients to gain an in-depth
                    understanding of their business requirements.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4 ">
                <div className="konw-few-cards p-5">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 ">04.</div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    Our business-focused approach allows us to develop bespoke
                    solutions that maximize the business potential of our
                    clients.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5 ">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 ">05.</div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    We provide solutions despite the size of the organization.
                    We have developed blockchain solutions for startups as well
                    as large organizations.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4 ">
                <div className="konw-few-cards p-5  ">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 ">06.</div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    We remain vigilant in monitoring emerging market trends and
                    identifying expansion opportunities, providing continual
                    innovation and progress to the industry.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Few Reason END  */}



        {/* Create Solution start  */}
        <div className="know-create-solu">
          <div className="container">
            <div className="text-white">
              <div className="p-3">
                <p className="fs-3 know-create-solu-heading ">
                  Contact us today to learn more about<br /> how we can help you with your IT needs.
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 col-sm-12 col-xl-6  ">
                <div className="row  justify-content-center text-white p-5">
                  <div className="col-sm-6 p-2">
                    <ul className=" konw-solution-list">
                      <li className="p-1" onClick={() => seted(1)}>
                        <div className="active2 light-color p-1">Business</div>
                      </li>
                      <li className="p-1" onClick={() => seted(2)}>
                        <div className="light-color p-1">Consumer</div>
                      </li>
                      <li className="p-1" onClick={() => seted(3)}>
                        <div className="light-color p-1">Finance</div>
                      </li>
                      <li className="p-1" onClick={() => seted(4)}>
                        <div className="light-color p-1">
                          Retail & Ecommerce
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(5)}>
                        <div className="light-color p-1">
                          Government & Public
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(6)} s>
                        <div className="light-color p-1">Automobile</div>
                      </li>
                      <li className="p-1" onClick={() => seted(7)}>
                        <div className="light-color p-1">Healthcare</div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 p-2 ">
                    <ul className="konw-solution-list">
                      <li className="p-1" onClick={() => seted(8)}>
                        <div className="light-color p-1">Entertainment</div>
                      </li>
                      <li className="p-1" onClick={() => seted(9)}>
                        <div className="light-color p-1">Oil, Gas & Mining</div>
                      </li>
                      <li className="p-1" onClick={() => seted(10)}>
                        <div className="light-color p-1">
                          Banking & Insurance
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(11)}>
                        <div className="light-color p-1">Educational</div>
                      </li>
                      <li className="p-1" onClick={() => seted(12)}>
                        <div className="light-color p-1">
                          Travel & Transport
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(13)}>
                        <div className="light-color p-1">
                          Logistics & Distribution
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(14)}>
                        <div className="light-color p-1">Manufacturing</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-xl-6  bg-solu-content ">
                <div className=" p-3">
                  <div className="solu-content-text   mt-5">

                    <div className="   ">
                      {ed == 1 ? (
                        <>
                          <h1 className="know-solu-content">Business</h1>
                          <p className="light-color">
                            Enterprise Mobile app Solutions, HRMS solution,
                            GST Invoice App, Taxi Booking App, Event Mangement
                            App, Cloud-Based File Storage Platform, Workflow
                            Management System,
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 2 ? (
                        <>
                          <h1 className="know-solu-content">Consumer</h1>
                          <p className="light-color">
                            City Guide App, Online Flight Ticket Booking,
                            Online Photo Sharing App, Online Mall, Instant
                            E-Gifting Platform, Online Publication System.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 3 ? (
                        <>
                          <h1 className="know-solu-content">Finance</h1>
                          <p className="light-color">
                            Stock Market Analytic Application, Social Finance
                            App, Money lending app, Risk Data Management
                            Solution, e-wallets.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 4 ? (
                        <>
                          <h1 className="know-solu-content">Retail & Ecommerce</h1>
                          <p className="light-color">
                            iBeacon App for Retail Business, Digital Photo
                            Frame App, Online Mall, Accounting Application,
                            Portal for Bill Tracking.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 5 ? (
                        <>
                          <h1 className="know-solu-content">Government & Public</h1>
                          <p className="light-color">
                            Enterprise Resource Planning, Business Process
                            reengineering, Systems integration, IT Security.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 6 ? (
                        <>
                          <h1 className="know-solu-content">Automobile</h1>
                          <p className="light-color">
                            Quality Inspection System, Enterprise Asset
                            Management, Service Parts Management, Supplier
                            Collaboration, Product Cost Analytics
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 7 ? (
                        <>
                          <h1 className="know-solu-content">Healthcare</h1>
                          <p className="light-color">
                            Health apps, fitness app, Medical Document
                            Management, Health Information Technology
                            Services, Self Healing App, Remote patient
                            Monitoring App.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 8 ? (
                        <>
                          <h1 className="know-solu-content">Entertainment</h1>
                          <p className="light-color">
                            Live Video Streaming Apps, Music apps, Magazine
                            reader app, social networking apps.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 9 ? (
                        <>
                          <h1 className="know-solu-content">Oil, Gas & Mining</h1>
                          <p className="light-color">
                            Data Quality Management Tool, Warning System,
                            Terminal Automation System.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 10 ? (
                        <>
                          <h1 className="know-solu-content">Banking & Insurance</h1>
                          <p className="light-color">
                            Identity & Access Management System, Robotic
                            Process Automation, Agile and Data Center
                            Management, Global Insurance Management System,
                            Loss Prevention Solution, Digital Asset Mangement.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 11 ? (
                        <>
                          <h1 className="know-solu-content">Educational</h1>
                          <p className="light-color">
                            Cloud-Based Education Platform, Learning
                            Management System, Online Examination System,
                            Consulting Services, Systems Integration,
                            E-learning Platform, School Management System,
                            Training and Consulting-Portal.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 12 ? (
                        <>
                          <h1 className="know-solu-content">Travel & Transport</h1>
                          <p className="light-color">
                            Online Ticket Booking App, Taxi app, vehicle
                            tracking solution
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 13 ? (
                        <>
                          <h1 className="know-solu-content">Logistics & Distribution</h1>
                          <p className="light-color">
                            Real time tracking solution, field management
                            system, Workforce Management Solution , Fleet
                            Management Solution.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 14 ? (
                        <>
                          <h1 className="know-solu-content">Manufacturing</h1>
                          <p className="light-color">
                            Product Lifecycle Management, Enterprise Asset
                            Mangement, Warehouse Mangement, Sales & Marketing
                            Mangement, Service Parts Management,
                            Transportation Mangement.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* Create Solution start  */}

        {/* schedule  start  */}
        <div className=" konw-schedule-bg py-4 ">
          <div className="d-flex justify-content-center text-white">
            <div className="col-md-4 ">
              <h1 className="text-center my-4 ">
                Schedule
                <span className="konw-schedule-span"> Free Consultation</span>
              </h1>
            </div>
          </div>

          <div className="container text-white py-4">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <Form />
              </div>
            </div>
          </div>
        </div>
        {/* schedule  End  */}

        {/* Footer Start  */}
        <div className="know-footer bg-dark light-color p-3">
          <div className="container ">
            <div className="row light-color">
              <div className="col-md-8 d-flex p-2">
                <p className="text-white">
                  Copyright © Infograins Solutions Pvt. Ltd. 2023-All rights
                  reserved.
                  <span className="main-color ms-3">Privacy-Policy</span>
                  <span className="main-color ms-3">Sitemap</span>
                </p>
              </div>
              <div className="col-md-4 p-2 ">
                <div className="text-end">
                  <a href="https://www.facebook.com/infograins/" target="_blank"><FaFacebookF className="text-white me-4" /></a>
                  <a href="https://twitter.com/infograinssoft?s=20" target="_blank"><FaTwitter className="text-white me-4" /></a>
                  <a href="https://www.instagram.com/infograins/" target="_blank"><FaInstagram className="text-white me-4" /></a>
                  <a href="https://www.linkedin.com/company/infograin-software-solutions/" target="_blank"><FaLinkedinIn className="text-white me-4" /></a>
                  <a href="https://join.skype.com/invite/NqBQ11qKBCxI" target="_blank"><FaSkype className="text-white " /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='top-btn-wrap'>
          <button className='btn'><BiUpArrowAlt /></button>
        </div>


        {/* Footer End  */}
      </section>
      <div className='whats_app_div'>
        <a href='https://wa.me/+919770477239' target={'_blank'} rel='noreferrer'><img src={WhatsApp} alt="whatsapp" loading='lazy' /></a>
      </div>
    </>
  );
}
